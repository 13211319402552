import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import commonReducer from "./reducers/commonReducer";
import userReducer from "./reducers/userReducer";

const store = configureStore({
  reducer: {
    common: commonReducer,
    user: userReducer,
  },
  middleware: [thunk]
});

export default store;