import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.css";

const Home = React.lazy(() => import("./pages/Home"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const AdminDashboard = React.lazy(() => import("./pages/admin/Dashboard"));
const AdminUsers = React.lazy(() => import("./pages/admin/Users"));
const SignIn = React.lazy(() => import("./pages/auth/SignIn"));
const SignUp = React.lazy(() => import("./pages/auth/SignUp"));
const VerifyEmail = React.lazy(() => import("./pages/auth/VerifyEmail"));
const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"));
const UserSettings = React.lazy(() => import("./pages/UserSettings"));
const PrivacyPolicy = React.lazy(() => import("./pages/static/PrivacyPolicy"));
const OurService = React.lazy(() => import("./pages/static/OurService"));
const OurPhilosophy = React.lazy(() => import("./pages/static/OurPhilosophy"));
const Pricing = React.lazy(() => import("./pages/static/Pricing"));
const FAQ = React.lazy(() => import("./pages/static/FAQ"));
const Blogs = React.lazy(() => import("./pages/static/Blogs"));
const ContactUs = React.lazy(() => import("./pages/static/ContactUs"));
const NotFound = React.lazy(() => import("./pages/NotFound"));

const TOS = React.lazy(() => import("./pages/static/TOS"));
function App() {
  return (
    <Router>
      <Suspense>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/dashboard/users" element={<AdminUsers />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-pwd" element={<ForgotPassword />} />
          <Route path="/reset-pwd" element={<ResetPassword />} />
          <Route path="/settings" element={<UserSettings />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/tos" element={<TOS />} />
          <Route path="/our-service" element={<OurService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/our-philosophy" element={<OurPhilosophy />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<FAQ />} />
        </Routes>
        <ToastContainer position="top-right" autoClose="3000" hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable theme="colored" />
      </Suspense>
    </Router>
  )
}

export default App;
