import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  user: window.localStorage.getItem("user") ?
    JSON.parse(window.localStorage.getItem("user")) : {
      id: 0,
      username: null,
      email: null,
      password: null,
      role: null,
    },
  token: window.localStorage.getItem("token") ?
    JSON.parse(window.localStorage.getItem("token")) : null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      window.localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setToken(state, action) {
      state.token = action.payload;
      window.localStorage.setItem("token", JSON.stringify(action.payload));
    },
    reset() {
      return initialState;
    },
  }
});

export const {
  setUser,
  setToken,
  reset,
} = userSlice.actions;

export default userSlice.reducer;